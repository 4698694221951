// Generated by Framer (bdd6aa1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["LuWuZm4lK"];

const variantClassNames = {LuWuZm4lK: "framer-v-f10wzm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, schattenDarstellen, screenshot, width, ...props}) => { return {...props, dD1T0e5lE: schattenDarstellen ?? props.dD1T0e5lE ?? true, VTparqvEX: screenshot ?? props.VTparqvEX ?? {src: new URL("assets/4096/qvkADDm5jyc2nHJZkccgmRh5qeM.png", import.meta.url).href, srcSet: `${new URL("assets/512/qvkADDm5jyc2nHJZkccgmRh5qeM.png", import.meta.url).href} 512w, ${new URL("assets/1024/qvkADDm5jyc2nHJZkccgmRh5qeM.png", import.meta.url).href} 1024w, ${new URL("assets/2048/qvkADDm5jyc2nHJZkccgmRh5qeM.png", import.meta.url).href} 2048w, ${new URL("assets/4096/qvkADDm5jyc2nHJZkccgmRh5qeM.png", import.meta.url).href} 4096w, ${new URL("assets/qvkADDm5jyc2nHJZkccgmRh5qeM.png", import.meta.url).href} 5760w`}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;schattenDarstellen?: boolean;screenshot?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, dD1T0e5lE, VTparqvEX, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "LuWuZm4lK", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-KGYEs", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-f10wzm", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"LuWuZm4lK"} ref={ref} style={{...style}}>{dD1T0e5lE && (<Image background={{alt: "", fit: "fit", intrinsicHeight: 232, intrinsicWidth: 2492, pixelHeight: 232, pixelWidth: 2492, sizes: "calc(min(2360px, 100vw) * 1.2148)", src: new URL("assets/svFXfsNoUKiHOOSHD8SIs8qfow.png", import.meta.url).href, srcSet: `${new URL("assets/512/svFXfsNoUKiHOOSHD8SIs8qfow.png", import.meta.url).href} 512w, ${new URL("assets/1024/svFXfsNoUKiHOOSHD8SIs8qfow.png", import.meta.url).href} 1024w, ${new URL("assets/2048/svFXfsNoUKiHOOSHD8SIs8qfow.png", import.meta.url).href} 2048w, ${new URL("assets/svFXfsNoUKiHOOSHD8SIs8qfow.png", import.meta.url).href} 2492w`}} className={"framer-w3yz22"} data-framer-name={"Schatten"} layoutDependency={layoutDependency} layoutId={"mmjjMn3j2"}/>)}<Image background={{alt: "", fit: "fill", sizes: "calc(min(2360px, 100vw) * 0.9165)", ...toResponsiveImage(VTparqvEX)}} className={"framer-6dl1kq"} data-framer-name={"Screenshot"} layoutDependency={layoutDependency} layoutId={"gH5AMCdC0"}/><Image background={{alt: "", fit: "fill", intrinsicHeight: 1950, intrinsicWidth: 2360, pixelHeight: 1950, pixelWidth: 2360, sizes: "min(2360px, 100vw)", src: new URL("assets/p3mVRoIKyvHDcZg5YSadPSbQ9c8.png", import.meta.url).href, srcSet: `${new URL("assets/512/p3mVRoIKyvHDcZg5YSadPSbQ9c8.png", import.meta.url).href} 512w, ${new URL("assets/1024/p3mVRoIKyvHDcZg5YSadPSbQ9c8.png", import.meta.url).href} 1024w, ${new URL("assets/2048/p3mVRoIKyvHDcZg5YSadPSbQ9c8.png", import.meta.url).href} 2048w, ${new URL("assets/p3mVRoIKyvHDcZg5YSadPSbQ9c8.png", import.meta.url).href} 2360w`}} className={"framer-r3xb2u"} data-framer-name={"Rahmen"} layoutDependency={layoutDependency} layoutId={"UoTixGtyq"}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-KGYEs [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KGYEs .framer-ehtfqy { display: block; }", ".framer-KGYEs .framer-f10wzm { height: 1950px; position: relative; width: 2360px; }", ".framer-KGYEs .framer-w3yz22 { flex: none; height: 14%; left: calc(49.95762711864409% - 121.48305084745763% / 2); position: absolute; top: calc(101.79487179487181% - 13.692307692307693% / 2); width: 121%; }", ".framer-KGYEs .framer-6dl1kq { flex: none; height: 63%; left: calc(50.00000000000002% - 91.65254237288136% / 2); position: absolute; top: calc(36.51282051282054% - 62.61538461538462% / 2); width: 92%; }", ".framer-KGYEs .framer-r3xb2u { bottom: 0px; flex: none; height: 100%; left: 0px; position: absolute; width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1950
 * @framerIntrinsicWidth 2360
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"dD1T0e5lE":"schattenDarstellen","VTparqvEX":"screenshot"}
 */
const FrameraV3v5xFO5: React.ComponentType<Props> = withCSS(Component, css, "framer-KGYEs") as typeof Component;
export default FrameraV3v5xFO5;

FrameraV3v5xFO5.displayName = "Mockup iMac Foto";

FrameraV3v5xFO5.defaultProps = {height: 1950, width: 2360};

addPropertyControls(FrameraV3v5xFO5, {dD1T0e5lE: {defaultValue: true, title: "Schatten darstellen?", type: ControlType.Boolean}, VTparqvEX: {__defaultAssetReference: "data:framer/asset-reference,qvkADDm5jyc2nHJZkccgmRh5qeM.png?originalFilename=app.balmung.at_+%282%29.png&preferredSize=auto", title: "Screenshot", type: ControlType.ResponsiveImage}} as any)

addFonts(FrameraV3v5xFO5, [])